import styled from "styled-components";
import {SM_MAX_SIZE} from "../../common/util/ViewSizeUtils";

export const StyledRegulamin = styled.div`&&& {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
  user-select: none; /* Standard */
  padding-bottom: 60px;

  .page-left-panel {
    width: calc(40vw - 160px);
    @media only screen and (max-width: ${SM_MAX_SIZE}px) {
      width: calc(50vw - 140px);
    }

    @media only screen and (max-width: ${350}px) {
      display: none;
    }
  }

  .page-title {
    width: 320px;

    @media only screen and (max-width: ${350}px) {
      width: 100%;
    }
  }

  .page-right-panel {
    width: calc(40vw - 160px);
    @media only screen and (max-width: ${SM_MAX_SIZE}px) {
      width: calc(50vw - 160px);
    }

    @media only screen and (max-width: ${350}px) {
      display: none;
    }
  }

  .doc-content {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 40px;
    padding-bottom: 40px;

    @media only screen and (max-width: ${899}px) {
      margin-left: 30px;
      margin-right: 30px;
      padding-left: 30px;
      padding-right: 30px;
    }

    @media only screen and (max-width: ${560}px) {
      margin-left: 0;
      margin-right: 0;
    }
  }


  @font-face {
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/l/font?kit=J7afnpV-BGlaFfdAhLEY67FIEjg&skey=a1029226f80653a8&v=v15) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/l/font?kit=J7aanpV-BGlaFfdAjAo9_pxqHxIZrCE&skey=cd2dd6afe6bf0eb2&v=v15) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  .lst-kix_list_4-1 > li {
    counter-increment: lst-ctn-kix_list_4-1
  }

  ol.lst-kix_list_3-1 {
    list-style-type: none
  }

  ol.lst-kix_list_3-2 {
    list-style-type: none
  }

  .lst-kix_list_3-1 > li {
    counter-increment: lst-ctn-kix_list_3-1
  }

  ol.lst-kix_list_3-3 {
    list-style-type: none
  }

  ol.lst-kix_list_3-4.start {
    counter-reset: lst-ctn-kix_list_3-4 0
  }

  ol.lst-kix_list_3-4 {
    list-style-type: none
  }

  .lst-kix_list_2-1 > li {
    counter-increment: lst-ctn-kix_list_2-1
  }

  ol.lst-kix_list_3-0 {
    list-style-type: none
  }

  .lst-kix_list_1-1 > li {
    counter-increment: lst-ctn-kix_list_1-1
  }

  ol.lst-kix_list_2-6.start {
    counter-reset: lst-ctn-kix_list_2-6 0
  }

  .lst-kix_list_3-0 > li:before {
    content: "" counter(lst-ctn-kix_list_3-0, decimal) ". "
  }

  ol.lst-kix_list_3-1.start {
    counter-reset: lst-ctn-kix_list_3-1 0
  }

  .lst-kix_list_3-1 > li:before {
    content: "" counter(lst-ctn-kix_list_3-1, lower-latin) ") "
  }

  .lst-kix_list_3-2 > li:before {
    content: "" counter(lst-ctn-kix_list_3-2, lower-roman) ". "
  }

  ol.lst-kix_list_1-8.start {
    counter-reset: lst-ctn-kix_list_1-8 0
  }

  .lst-kix_list_4-0 > li {
    counter-increment: lst-ctn-kix_list_4-0
  }

  ol.lst-kix_list_2-3.start {
    counter-reset: lst-ctn-kix_list_2-3 0
  }

  .lst-kix_list_3-5 > li:before {
    content: "" counter(lst-ctn-kix_list_3-5, lower-roman) ". "
  }

  .lst-kix_list_3-4 > li:before {
    content: "" counter(lst-ctn-kix_list_3-4, lower-latin) ". "
  }

  ol.lst-kix_list_1-5.start {
    counter-reset: lst-ctn-kix_list_1-5 0
  }

  .lst-kix_list_3-3 > li:before {
    content: "" counter(lst-ctn-kix_list_3-3, decimal) ". "
  }

  ol.lst-kix_list_3-5 {
    list-style-type: none
  }

  ol.lst-kix_list_3-6 {
    list-style-type: none
  }

  ol.lst-kix_list_3-7 {
    list-style-type: none
  }

  ol.lst-kix_list_3-8 {
    list-style-type: none
  }

  .lst-kix_list_3-8 > li:before {
    content: "" counter(lst-ctn-kix_list_3-8, lower-roman) ". "
  }

  .lst-kix_list_2-0 > li {
    counter-increment: lst-ctn-kix_list_2-0
  }

  .lst-kix_list_2-3 > li {
    counter-increment: lst-ctn-kix_list_2-3
  }

  .lst-kix_list_3-6 > li:before {
    content: "" counter(lst-ctn-kix_list_3-6, decimal) ". "
  }

  .lst-kix_list_4-3 > li {
    counter-increment: lst-ctn-kix_list_4-3
  }

  .lst-kix_list_3-7 > li:before {
    content: "" counter(lst-ctn-kix_list_3-7, lower-latin) ". "
  }

  ol.lst-kix_list_4-5.start {
    counter-reset: lst-ctn-kix_list_4-5 0
  }

  .lst-kix_list_1-2 > li {
    counter-increment: lst-ctn-kix_list_1-2
  }

  ol.lst-kix_list_3-7.start {
    counter-reset: lst-ctn-kix_list_3-7 0
  }

  ol.lst-kix_list_4-2.start {
    counter-reset: lst-ctn-kix_list_4-2 0
  }

  .lst-kix_list_3-2 > li {
    counter-increment: lst-ctn-kix_list_3-2
  }

  ol.lst-kix_list_2-2 {
    list-style-type: none
  }

  ol.lst-kix_list_2-3 {
    list-style-type: none
  }

  ol.lst-kix_list_2-4 {
    list-style-type: none
  }

  ol.lst-kix_list_2-5 {
    list-style-type: none
  }

  .lst-kix_list_1-4 > li {
    counter-increment: lst-ctn-kix_list_1-4
  }

  .lst-kix_list_4-4 > li {
    counter-increment: lst-ctn-kix_list_4-4
  }

  ol.lst-kix_list_2-0 {
    list-style-type: none
  }

  ol.lst-kix_list_1-6.start {
    counter-reset: lst-ctn-kix_list_1-6 0
  }

  ol.lst-kix_list_2-1 {
    list-style-type: none
  }

  .lst-kix_list_4-8 > li:before {
    content: " "
  }

  .lst-kix_list_4-7 > li:before {
    content: " "
  }

  ol.lst-kix_list_4-1.start {
    counter-reset: lst-ctn-kix_list_4-1 0
  }

  ol.lst-kix_list_4-8.start {
    counter-reset: lst-ctn-kix_list_4-8 0
  }

  ol.lst-kix_list_3-3.start {
    counter-reset: lst-ctn-kix_list_3-3 0
  }

  ol.lst-kix_list_2-6 {
    list-style-type: none
  }

  ol.lst-kix_list_2-7 {
    list-style-type: none
  }

  ol.lst-kix_list_2-8 {
    list-style-type: none
  }

  ol.lst-kix_list_1-0.start {
    counter-reset: lst-ctn-kix_list_1-0 0
  }

  .lst-kix_list_3-0 > li {
    counter-increment: lst-ctn-kix_list_3-0
  }

  .lst-kix_list_3-3 > li {
    counter-increment: lst-ctn-kix_list_3-3
  }

  ol.lst-kix_list_4-0.start {
    counter-reset: lst-ctn-kix_list_4-0 0
  }

  .lst-kix_list_3-6 > li {
    counter-increment: lst-ctn-kix_list_3-6
  }

  .lst-kix_list_2-5 > li {
    counter-increment: lst-ctn-kix_list_2-5
  }

  .lst-kix_list_2-8 > li {
    counter-increment: lst-ctn-kix_list_2-8
  }

  ol.lst-kix_list_3-2.start {
    counter-reset: lst-ctn-kix_list_3-2 0
  }

  .lst-kix_list_2-2 > li {
    counter-increment: lst-ctn-kix_list_2-2
  }

  ol.lst-kix_list_2-4.start {
    counter-reset: lst-ctn-kix_list_2-4 0
  }

  ol.lst-kix_list_4-7.start {
    counter-reset: lst-ctn-kix_list_4-7 0
  }

  ol.lst-kix_list_1-3 {
    list-style-type: none
  }

  ol.lst-kix_list_1-4 {
    list-style-type: none
  }

  .lst-kix_list_2-6 > li:before {
    content: "" counter(lst-ctn-kix_list_2-6, decimal) ". "
  }

  .lst-kix_list_2-7 > li:before {
    content: "" counter(lst-ctn-kix_list_2-7, lower-latin) ". "
  }

  .lst-kix_list_2-7 > li {
    counter-increment: lst-ctn-kix_list_2-7
  }

  .lst-kix_list_3-7 > li {
    counter-increment: lst-ctn-kix_list_3-7
  }

  ol.lst-kix_list_1-5 {
    list-style-type: none
  }

  ol.lst-kix_list_1-6 {
    list-style-type: none
  }

  ol.lst-kix_list_1-0 {
    list-style-type: none
  }

  .lst-kix_list_2-4 > li:before {
    content: "" counter(lst-ctn-kix_list_2-4, lower-latin) ". "
  }

  .lst-kix_list_2-5 > li:before {
    content: "" counter(lst-ctn-kix_list_2-5, lower-roman) ". "
  }

  .lst-kix_list_2-8 > li:before {
    content: "" counter(lst-ctn-kix_list_2-8, lower-roman) ". "
  }

  ol.lst-kix_list_1-1 {
    list-style-type: none
  }

  ol.lst-kix_list_1-2 {
    list-style-type: none
  }

  ol.lst-kix_list_4-6.start {
    counter-reset: lst-ctn-kix_list_4-6 0
  }

  ol.lst-kix_list_3-0.start {
    counter-reset: lst-ctn-kix_list_3-0 0
  }

  ol.lst-kix_list_4-3.start {
    counter-reset: lst-ctn-kix_list_4-3 0
  }

  ol.lst-kix_list_1-7 {
    list-style-type: none
  }

  .lst-kix_list_4-7 > li {
    counter-increment: lst-ctn-kix_list_4-7
  }

  .lst-kix_list_1-7 > li {
    counter-increment: lst-ctn-kix_list_1-7
  }

  ol.lst-kix_list_1-8 {
    list-style-type: none
  }

  ol.lst-kix_list_3-8.start {
    counter-reset: lst-ctn-kix_list_3-8 0
  }

  ol.lst-kix_list_2-5.start {
    counter-reset: lst-ctn-kix_list_2-5 0
  }

  .lst-kix_list_4-0 > li:before {
    content: " "
  }

  .lst-kix_list_2-6 > li {
    counter-increment: lst-ctn-kix_list_2-6
  }

  .lst-kix_list_3-8 > li {
    counter-increment: lst-ctn-kix_list_3-8
  }

  .lst-kix_list_4-1 > li:before {
    content: " "
  }

  .lst-kix_list_4-6 > li {
    counter-increment: lst-ctn-kix_list_4-6
  }

  ol.lst-kix_list_1-7.start {
    counter-reset: lst-ctn-kix_list_1-7 0
  }

  .lst-kix_list_4-4 > li:before {
    content: " "
  }

  ol.lst-kix_list_2-2.start {
    counter-reset: lst-ctn-kix_list_2-2 0
  }

  .lst-kix_list_1-5 > li {
    counter-increment: lst-ctn-kix_list_1-5
  }

  .lst-kix_list_4-3 > li:before {
    content: " "
  }

  .lst-kix_list_4-5 > li:before {
    content: " "
  }

  .lst-kix_list_4-2 > li:before {
    content: " "
  }

  .lst-kix_list_4-6 > li:before {
    content: " "
  }

  .lst-kix_list_1-8 > li {
    counter-increment: lst-ctn-kix_list_1-8
  }

  ol.lst-kix_list_1-4.start {
    counter-reset: lst-ctn-kix_list_1-4 0
  }

  .lst-kix_list_3-5 > li {
    counter-increment: lst-ctn-kix_list_3-5
  }

  ol.lst-kix_list_1-1.start {
    counter-reset: lst-ctn-kix_list_1-1 0
  }

  ol.lst-kix_list_4-0 {
    list-style-type: none
  }

  .lst-kix_list_3-4 > li {
    counter-increment: lst-ctn-kix_list_3-4
  }

  ol.lst-kix_list_4-1 {
    list-style-type: none
  }

  ol.lst-kix_list_4-4.start {
    counter-reset: lst-ctn-kix_list_4-4 0
  }

  ol.lst-kix_list_4-2 {
    list-style-type: none
  }

  ol.lst-kix_list_4-3 {
    list-style-type: none
  }

  .lst-kix_list_2-4 > li {
    counter-increment: lst-ctn-kix_list_2-4
  }

  ol.lst-kix_list_3-6.start {
    counter-reset: lst-ctn-kix_list_3-6 0
  }

  ol.lst-kix_list_1-3.start {
    counter-reset: lst-ctn-kix_list_1-3 0
  }

  ol.lst-kix_list_2-8.start {
    counter-reset: lst-ctn-kix_list_2-8 0
  }

  ol.lst-kix_list_1-2.start {
    counter-reset: lst-ctn-kix_list_1-2 0
  }

  ol.lst-kix_list_4-8 {
    list-style-type: none
  }

  .lst-kix_list_1-0 > li:before {
    content: "" counter(lst-ctn-kix_list_1-0, lower-latin) ") "
  }

  ol.lst-kix_list_4-4 {
    list-style-type: none
  }

  ol.lst-kix_list_4-5 {
    list-style-type: none
  }

  .lst-kix_list_1-1 > li:before {
    content: "" counter(lst-ctn-kix_list_1-1, lower-latin) ") "
  }

  .lst-kix_list_1-2 > li:before {
    content: "" counter(lst-ctn-kix_list_1-2, lower-roman) ". "
  }

  ol.lst-kix_list_2-0.start {
    counter-reset: lst-ctn-kix_list_2-0 0
  }

  ol.lst-kix_list_4-6 {
    list-style-type: none
  }

  ol.lst-kix_list_4-7 {
    list-style-type: none
  }

  .lst-kix_list_1-3 > li:before {
    content: "" counter(lst-ctn-kix_list_1-3, decimal) ". "
  }

  .lst-kix_list_1-4 > li:before {
    content: "" counter(lst-ctn-kix_list_1-4, lower-latin) ". "
  }

  ol.lst-kix_list_3-5.start {
    counter-reset: lst-ctn-kix_list_3-5 0
  }

  .lst-kix_list_1-0 > li {
    counter-increment: lst-ctn-kix_list_1-0
  }

  .lst-kix_list_4-8 > li {
    counter-increment: lst-ctn-kix_list_4-8
  }

  .lst-kix_list_1-6 > li {
    counter-increment: lst-ctn-kix_list_1-6
  }

  .lst-kix_list_1-7 > li:before {
    content: "" counter(lst-ctn-kix_list_1-7, lower-latin) ". "
  }

  ol.lst-kix_list_2-7.start {
    counter-reset: lst-ctn-kix_list_2-7 0
  }

  .lst-kix_list_1-3 > li {
    counter-increment: lst-ctn-kix_list_1-3
  }

  .lst-kix_list_1-5 > li:before {
    content: "" counter(lst-ctn-kix_list_1-5, lower-roman) ". "
  }

  .lst-kix_list_1-6 > li:before {
    content: "" counter(lst-ctn-kix_list_1-6, decimal) ". "
  }

  li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt
  }

  .lst-kix_list_2-0 > li:before {
    content: "" counter(lst-ctn-kix_list_2-0, decimal) ". "
  }

  .lst-kix_list_2-1 > li:before {
    content: "" counter(lst-ctn-kix_list_2-1, lower-latin) ". "
  }

  ol.lst-kix_list_2-1.start {
    counter-reset: lst-ctn-kix_list_2-1 0
  }

  .lst-kix_list_4-5 > li {
    counter-increment: lst-ctn-kix_list_4-5
  }

  .lst-kix_list_1-8 > li:before {
    content: "" counter(lst-ctn-kix_list_1-8, lower-roman) ". "
  }

  .lst-kix_list_2-2 > li:before {
    content: "" counter(lst-ctn-kix_list_2-2, lower-roman) ". "
  }

  .lst-kix_list_2-3 > li:before {
    content: "" counter(lst-ctn-kix_list_2-3, decimal) ". "
  }

  .lst-kix_list_4-2 > li {
    counter-increment: lst-ctn-kix_list_4-2
  }

  ol {
    margin: 0;
    padding: 0
  }

  table td, table th {
    padding: 0
  }

  .c0 {
    margin-left: 18pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.5;
    orphans: 2;
    widows: 2;
    text-align: justify
  }

  .c3 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.5;
    orphans: 2;
    widows: 2;
    text-align: justify
  }

  .c12 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Times New Roman";
    font-style: normal
  }

  .c1 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Times New Roman";
    font-style: normal
  }

  .c9 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.5;
    orphans: 2;
    widows: 2;
    text-align: center
  }

  .c5 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.5;
    orphans: 2;
    widows: 2;
    text-align: justify
  }

  .c10 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.5;
    orphans: 2;
    widows: 2;
    text-align: justify
  }

  .c13 {
    background-color: #ffffff;
  }

  .c4 {
    font-size: 12pt;
    font-family: "Times New Roman";
    font-weight: 400
  }

  .c6 {
    margin-left: 54pt;
    padding-left: 0pt
  }

  .c2 {
    padding: 0;
    margin: 0
  }

  .c11 {
    padding-left: 0pt
  }

  .c7 {
    height: 11pt
  }

  .c8 {
    margin-left: 18pt
  }

  .title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: "Georgia";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  li {
    color: #000000;
    font-size: 11pt;
    font-family: "Calibri"
  }

  p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Calibri"
  }

  h1 {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 24pt;
    padding-bottom: 6pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h2 {
    //padding-top: 18pt;
    color: #000000;
    font-weight: 700;
    font-size: 18pt;
    //padding-bottom: 4pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h3 {
    padding-top: 14pt;
    color: #000000;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h4 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }
}`;
