import React, {FC} from "react";
import {StyledRegulamin} from "./Regulamin.style";
import {Title} from "../../common/components/title/Title.component";
import {Colors} from "../../common/style/ColorUtils";

interface RegulaminProps {
}

export const Regulamin: FC<RegulaminProps> = (props: RegulaminProps) => {

    const onCopy = (e: any) => {
        e.preventDefault();
    }

    return <StyledRegulamin onCopy={(e) => onCopy(e)}>
        <Title title={"REGULAMIN"} panelsColor={Colors.WHITE} textColor={Colors.WHITE}/>
        <div className="c13 doc-content">
            <ol className="c2 lst-kix_list_2-0 start" start={1}>
                <li className="c0 li-bullet-0"><span
                    className="c1">Studio Tatua&#380;u Le&#347;ny Szept Tattoo &amp; Piercing sp. z. o.o znajduje si&#281; przy ulicy Brackiej 23/46 w Warszawie</span>
                </li>
            </ol>
            <p className="c5 c8"><span className="c1">Studio Tatua&#380;u Le&#347;ny Szept Tattoo &amp; Piercing sp. z. o.o, zwane dalej Studiem, <br/>NIP: 5242948165 <br/>e-mail: lesny.szept.studio@gmail.com</span>
            </p>
            <p className="c5 c8"><span className="c1">Telefon kontaktowy: 513 475 623</span></p>
            <ol className="c2 lst-kix_list_2-0" start={2}>
                <li className="c0 li-bullet-0"><span className="c1">Zabieg wykonania tatua&#380;u oraz piercingu odbywa si&#281; w sterylnych warunkach, z u&#380;yciem jednorazowych igie&#322;, &#347;rodk&oacute;w higieny, sterylnych narz&#281;dzi.</span>
                </li>
                <li className="c0 li-bullet-0"><span className="c1">Klient ma prawo sprawdzi&#263; przed zabiegiem sterylno&#347;&#263; igie&#322; oraz rur (grip&oacute;w). Na wniosek Klienta mo&#380;e on uczestniczy&#263; w procesie dezynfekcji i na&#347;wietlania narz&#281;dzi &#347;wiat&#322;em UV. </span>
                </li>
                <li className="c0 li-bullet-0"><span
                    className="c1">W Studio obowi&#261;zuje ca&#322;kowity zakaz spo&#380;ywania alkoholu, palenia tytoniu lub za&#380;ywania innych &#347;rodk&oacute;w odurzaj&#261;cych.</span>
                </li>
                <li className="c0 li-bullet-0"><span
                    className="c1">Na wykonanie tatua&#380;u lub nale&#380;y przyj&#347;&#263; samodzielnie. Zabrania si&#281; przychodzenia z osobami towarzysz&#261;cymi. </span>
                </li>
                <li className="c0 li-bullet-0"><span
                    className="c1">Zabrania si&#281; przyprowadzania zwierz&#261;t.</span></li>
                <li className="c0 li-bullet-0"><span className="c1">Studio wykonuje zabiegi wy&#322;&#261;cznie osobom pe&#322;noletnim posiadaj&#261;cym </span><span
                    className="c4">pe&#322;n&#261;</span><span
                    className="c1">&nbsp;zdolno&#347;&#263; do czynno&#347;ci prawnych.</span></li>
                <li className="c0 li-bullet-0"><span
                    className="c1">24h przed wizyt&#261; zabrania si&#281;: picia napoj&oacute;w energetyzuj&#261;cych, kawy, alkoholu, przyjmowania wszelkich &#347;rodk&oacute;w odurzaj&#261;cych. W celu przyspieszenia zabiegu - zalecamy, by przynajmniej tydzie&#324; przed planowanym wykonaniem tatua&#380;u dok&#322;adnie nawil&#380;a&#263; miejsce, w kt&oacute;rym ma zosta&#263; on wykonany.</span>
                </li>
                <li className="c0 li-bullet-0"><span className="c4">Przeciwwskazaniami</span><span
                    className="c1">&nbsp;do wykonania tatua&#380;u oraz piercingu s&#261; w szczeg&oacute;lno&#347;ci:</span>
                </li>
            </ol>
            <ol className="c2 lst-kix_list_3-1 start" start={1}>
                <li className="c5 c6 li-bullet-0"><span className="c1">padaczka,</span></li>
                <li className="c5 c6 li-bullet-0"><span
                    className="c1">choroby serca i uk&#322;adu kr&#261;&#380;enia, </span></li>
                <li className="c5 c6 li-bullet-0"><span className="c1">hemofilia, </span></li>
                <li className="c5 c6 li-bullet-0"><span className="c1">&#322;uszczyca, </span></li>
                <li className="c5 c6 li-bullet-0"><span className="c1">atopowe zapalenie sk&oacute;ry, </span></li>
                <li className="c5 c6 li-bullet-0"><span className="c1">liszaj p&#322;aski, </span></li>
                <li className="c5 c6 li-bullet-0"><span className="c1">bielactwo nabyte, </span></li>
                <li className="c5 c6 li-bullet-0"><span
                    className="c1">infekcje sk&oacute;ry, grzybice sk&oacute;ry, </span></li>
                <li className="c5 c6 li-bullet-0"><span className="c1">nowotwory, </span></li>
                <li className="c5 c6 li-bullet-0"><span className="c1">wirus HIV, HCV, </span></li>
                <li className="c5 c6 li-bullet-0"><span className="c1">choroba Le&#347;niowskiego i Crohna</span></li>
                <li className="c5 c6 li-bullet-0"><span className="c1">&#347;wie&#380;a opalenizna,</span></li>
                <li className="c5 c6 li-bullet-0"><span className="c1">antybiotykoterapia,</span></li>
                <li className="c5 c6 li-bullet-0"><span
                    className="c1">inne stany zapalne, gor&#261;czka, os&#322;abienie, </span></li>
                <li className="c5 c6 li-bullet-0"><span
                    className="c1">ci&#261;&#380;a, karmienie piersi&#261;, okres menstruacji</span><span
                    className="c1">,</span></li>
                <li className="c5 c6 li-bullet-0"><span className="c1">kuracja izotekiem, b&#261;d&#378; innymi lekami maj&#261;cymi na celu zwalczenie tr&#261;dziku.</span>
                </li>
            </ol>
            <ol className="c2 lst-kix_list_2-0" start={10}>
                <li className="c0 li-bullet-0"><span className="c1">Wycena tatua&#380;u dokonywana jest w ka&#380;dym przypadku indywidualnie i jest uzale&#380;niona od takich czynnik&oacute;w jak:</span>
                </li>
            </ol>
            <ol className="c2 lst-kix_list_1-0 start" start={1}>
                <li className="c3 li-bullet-0"><span className="c1">powierzchnia tatua&#380;u,</span></li>
                <li className="c3 li-bullet-0"><span
                    className="c1">stopie&#324; skomplikowania i trudno&#347;ci wzoru, </span></li>
                <li className="c3 li-bullet-0"><span className="c1">ilo&#347;ci zmian w projekcie,</span></li>
                <li className="c3 li-bullet-0"><span
                    className="c1">ilo&#347;ci godzin potrzebnych na wykonanie tatua&#380;u,</span></li>
                <li className="c3 li-bullet-0"><span className="c1">czasu potrzebnego na wykonanie projektu,</span></li>
                <li className="c3 li-bullet-0"><span
                    className="c1">ilo&#347;ci i powierzchni kolor&oacute;w u&#380;ytych w tatua&#380;u,</span></li>
                <li className="c3 li-bullet-0"><span className="c1">miejsca na ciele.</span></li>
            </ol>
            <ol className="c2 lst-kix_list_2-0" start={11}>
                <li className="c0 li-bullet-0"><span
                    className="c1">Wyceny dokonuje Studio lub Tatuator, kt&oacute;ry b&#281;dzie wykonywa&#322; zabieg wykonania tatua&#380;u.</span>
                </li>
                <li className="c0 li-bullet-0"><span className="c1">Do zawarcia umowy wykonania tatua&#380;u lub piercingu dochodzi w momencie wys&#322;ania zadatku, a nast&#281;pnie podpisania jej &nbsp;przez obie Strony.</span>
                </li>
                <li className="c0 li-bullet-0"><span className="c1">Cena tatua&#380;u jest oszacowana i wskazana Klientowi przy podpisaniu umowy na wykonanie tatua&#380;u.</span>
                </li>
                <li className="c0 li-bullet-0"><span
                    className="c1">Klient w dniu podpisania umowy na wykonanie tatua&#380;u jest zobowi&#261;zany do zap&#322;aty zadatku w wysoko&#347;ci od 150 do </span><span
                    className="c4">300</span><span
                    className="c1">z&#322; (w zale&#380;no&#347;ci od planowanej d&#322;ugo&#347;ci sesji tatua&#380;u). Kwota zadatku zaliczana jest na poczet przys&#322;uguj&#261;cego Przyjmuj&#261;cemu zam&oacute;wienie wynagrodzenia</span>
                </li>
                <li className="c0 li-bullet-0"><span className="c1">Klient wysy&#322;aj&#261;c zadatek na numer konta studia, zobowi&#261;zany jest do wpisania w tytule przelewu kolejno: swojego imienia; nazwy/pseudonimu osoby, u kt&oacute;rej b&#281;dzie mia&#322; wykonywan&#261; us&#322;ug&#281;; dat&#281; (dzie&#324;, miesi&#261;c i rok) wykonywania danej us&#322;ugi.</span>
                </li>
            </ol>
            <p className="c5 c8"><span
                className="c1">Je&#347;li Zamawiaj&#261;cy nie zawrze w tytule przelewu kt&oacute;rejkolwiek z powy&#380;szych informacji - Studio traktuje taki&#380; przelew jako dobrowolny datek na dowoln&#261; wybran&#261; przez Studio Dzia&#322;alno&#347;&#263; Charytatywn&#261;. Datki b&#281;d&#261; przekazywane na wybran&#261; Fundacj&#281; w ostatnim dniu kwarta&#322;u.</span>
            </p>
            <ol className="c2 lst-kix_list_2-0" start={16}>
                <li className="c0 li-bullet-0"><span className="c1">Zadatek jest op&#322;at&#261;, kt&oacute;ra gwarantuje rezerwacj&#281; terminu u danego Cz&#322;onka Zespo&#322;u Studia na wybrany wcze&#347;niej przez Zamawiaj&#261;cego wz&oacute;r. Planowan&#261; wielko&#347;&#263;, wygl&#261;d i miejsce wzoru Zamawiaj&#261;cy ma obowi&#261;zek ustali&#263; z Osob&#261; Wykonuj&#261;c&#261; Us&#322;ug&#281; przed wp&#322;at&#261; zadatku.</span>
                </li>
            </ol>
            <p className="c5 c8"><span
                className="c1">Z tego wzgl&#281;du, je&#347;li klient zmieni koncepcj&#281; tatua&#380;u przed wykonaniem sesji - zadatek przepada i by odby&#263; wizyt&#281; we wcze&#347;niej ustalonym, b&#261;d&#378; innym terminie (w zale&#380;no&#347;ci od tego, czy Tatuator b&#281;dzie w stanie um&oacute;wionego dnia wykona&#263; inny koncept tatua&#380;u) trzeba wp&#322;aci&#263; zadatek ponownie.</span>
            </p>
            <p className="c5"><span
                className="c1">Je&#347;li Klient zmieni koncepcj&#281; w dniu wykonywania tatua&#380;u, ze wzgl&#281;du na konieczno&#347;&#263; wykonania nowego projektu, Tatuator musi ponownie pobra&#263; tak&#261; sam&#261; kwot&#281; zadatku, jak&#261; wcze&#347;niej wp&#322;aci&#322; na konto studia Zamawiaj&#261;cy. </span>
            </p>
            <p className="c5"><span
                className="c1">W razie nie pojawienia si&#281; Klienta na um&oacute;wionej sesji - zadatek przepada.</span>
            </p>
            <ol className="c2 lst-kix_list_2-0" start={17}>
                <li className="c0 li-bullet-0"><span
                    className="c1">By zarezerwowa&#263; Klientowi termin na czas zaksi&#281;gowania przelewu, Klient ma obowi&#261;zek wys&#322;a&#263; potwierdzenie wykonania przelewu do osoby, kt&oacute;ra przyjmuje zlecenie. W razie podrobienia przelewu - Studio ma obowi&#261;zek zg&#322;osi&#263; takie zaj&#347;cie do odpowiednich organ&oacute;w.</span>
                </li>
                <li className="c0 li-bullet-0"><span className="c1">Projekt tatua&#380;u wykonywany jest indywidualnie dla ka&#380;dego Klienta. Projekt (nie licz&#261;c &lsquo;&rsquo;wolnych wzor&oacute;w&rsquo;&rsquo; wystawianych przez Tatuator&oacute;w) przedstawiany jest Klientowi w dniu planowanego wykonania tatua&#380;u. W tym dniu Tatuator zobowi&#261;zuje si&#281; do wprowadzenia zmian w projekcie, b&#261;d&#378; w razie potrzeby - wykonania go od nowa wraz z Zamawiaj&#261;cym, je&#347;li jest to konieczne. </span>
                </li>
            </ol>
            <p className="c5 c8"><span
                className="c4">Tatuatorzy w Studiu ,,Le&#347;ny Szept&rsquo;&rsquo; nie wykonuj&#261; kopii prac innych tatuator&oacute;w, b&#261;d&#378; grafik. Je&#347;li wz&oacute;r mimo stara&#324; Tatuatora nie b&#281;dzie odpowiada&#322; Klientowi - sesja tatua&#380;u mo&#380;e zosta&#263; odwo&#322;ana. W takim wypadku zadatek przepada.</span>
            </p>
            <ol className="c2 lst-kix_list_2-0" start={19}>
                <li className="c0 li-bullet-0"><span
                    className="c1">Klient obowi&#261;zany jest do zap&#322;aty ca&#322;ej ceny za wykonanie tatua&#380;u lub piercingu najp&oacute;&#378;niej w dniu wykonania tatua&#380;u lub piercingu. Zap&#322;ata nast&#281;puje w Studio w dniu um&oacute;wionego spotkania lub przelewem na rachunek bankowy. Przy p&#322;atno&#347;ci elektronicznej warunkiem rozpocz&#281;cia wykonania us&#322;ugi przez Tatuatora lub Piercera jest okazanie potwierdzenia dokonania przelewu. </span>
                </li>
                <li className="c0 li-bullet-0"><span
                    className="c1">W przypadku, gdy wykonanie tatua&#380;u wi&#261;za&#322;oby si&#281; z wi&#281;ksz&#261; ilo&#347;ci&#261; sesji (wi&#281;cej ni&#380; jedna), w&oacute;wczas wysoko&#347;&#263; nale&#380;nego wynagrodzenia b&#281;dzie ustalana od ka&#380;dej sesji pojedynczo. Zamawiaj&#261;cy b&#281;dzie zobowi&#261;zany do zap&#322;aty wynagrodzenia z g&oacute;ry za dan&#261; sesj&#281; najp&oacute;&#378;niej w dniu wykonania sesji.</span>
                </li>
                <li className="c0 li-bullet-0"><span className="c1">Je&#380;eli Klient nie stawi si&#281; w Studio w wyznaczonym terminie Przyjmuj&#261;cy zam&oacute;wienie mo&#380;e bez wyznaczania dodatkowego terminu od umowy odst&#261;pi&#263;. W takim przypadku Przyjmuj&#261;cy zam&oacute;wienie zachowuje otrzymany zadatek. </span>
                </li>
                <li className="c0 li-bullet-0"><span className="c1">Je&#347;li Klient przy zapisywaniu si&#281; na dany dzie&#324; nie okre&#347;li, na kt&oacute;r&#261; dok&#322;adnie godzin&#281; chce um&oacute;wi&#263; wizyt&#281;, Pracownik studia ma prawo zapisa&#263; go na pierwsz&#261; woln&#261; godzin&#281; danego dnia. Wszelkie godziny i terminy, na kt&oacute;re mo&#380;na si&#281; um&oacute;wi&#263; s&#261; dost&#281;pne w &lsquo;&rsquo;wyr&oacute;&#380;nionych relacjach&rsquo;&rsquo; Pracownik&oacute;w, oraz &lsquo;&rsquo;wyr&oacute;&#380;nionej relacji&rsquo;&rsquo; na Instagramie studia ,,Wolne Terminy&rsquo;&rsquo;. </span>
                </li>
            </ol>
            <p className="c5 c8"><span className="c1">Je&#347;li Klient nie stawi si&#281; w danym dniu o wyznaczonej godzinie na wizyt&#281; - zadatek przepada.</span>
            </p>
            <ol className="c2 lst-kix_list_2-0" start={23}>
                <li className="c0 li-bullet-0"><span
                    className="c1">Klienci maj&#261; obowi&#261;zek stawia&#263; si&#281; w Studio w okre&#347;lonym terminie i okre&#347;lonym czasie. Je&#347;li klient zapiany na tatua&#380; sp&oacute;&#378;ni si&#281; na wizyt&#281; wi&#281;cej ni&#380; 20 minut - jest to jednoznaczne z odwo&#322;aniem wizyty (zadatek przepada), b&#261;d&#378; (je&#347;li Tatuator zdecyduje, &#380;e mimo sp&oacute;&#378;nienia b&#281;dzie w stanie wykona&#263; swoj&#261; prac&#281; z nale&#380;yt&#261; precyzj&#261; i bez szkody dla innych, zapisanych tego dnia Klient&oacute;w) z obowi&#261;zkiem dop&#322;aty 100z&#322; w dniu um&oacute;wionej wizyty, bezpo&#347;rednio po wykonaniu us&#322;ugi.</span>
                </li>
                <li className="c0 li-bullet-0"><span
                    className="c1">Klienci maj&#261; obowi&#261;zek stawia&#263; si&#281; w Studio w okre&#347;lonym terminie i okre&#347;lonym czasie. Je&#347;li klient zapiany na piercing sp&oacute;&#378;ni si&#281; na wizyt&#281; wi&#281;cej ni&#380; 15 minut - jest to jednoznaczne z odwo&#322;aniem wizyty (zadatek przepada), b&#261;d&#378; (je&#347;li Piercer zdecyduje, &#380;e mimo sp&oacute;&#378;nienia b&#281;dzie w stanie wykona&#263; swoj&#261; prac&#281; z nale&#380;yt&#261; precyzj&#261; i bez szkody dla innych, zapisanych tego dnia Klient&oacute;w) z obowi&#261;zkiem dop&#322;aty 50z&#322; w dniu um&oacute;wionej wizyty, bezpo&#347;rednio po wykonaniu us&#322;ugi.</span>
                </li>
                <li className="c0 li-bullet-0"><span className="c1">Przed przyst&#261;pieniem do wykonania tatua&#380;u lub piercingu Klient ma obowi&#261;zek poinformowa&#263; Studio o swoich chorobach, wszelkich zmianach nask&oacute;rnych (trwa&#322;ych i tymczasowych) oraz wszelkich innych dolegliwo&#347;ciach zdrowotnych, stanach zapalnych, gor&#261;czce, przyjmowanych lekach, kt&oacute;re mog&#261; wyklucza&#263; wykonanie tatua&#380;u lub piercingu.</span>
                </li>
                <li className="c0 li-bullet-0"><span className="c1">Ze wzgl&#281;du na szacunek do pracy Ekipy Studia, oraz innych Klient&oacute;w - prosimy, by nie wchodzi&#263; do studia wcze&#347;niej, ni&#380; 10 minut przed um&oacute;wion&#261; wizyt&#261;. Je&#347;li Klient stawi si&#281; wcze&#347;niej - nie b&#281;dzie wpuszczony do studia. Ze wzgl&#281;du na Covid staramy si&#281; ograniczy&#263; do minimum ilo&#347;&#263; os&oacute;b przebywaj&#261;cych w Studiu.</span>
                </li>
                <li className="c0 li-bullet-0"><span
                    className="c1">Przed wej&#347;ciem do studia Klienci zobowi&#261;zani s&#261; do za&#322;o&#380;enia jednorazowych ochraniaczy ochronnych, znajduj&#261;cych si&#281; przy wej&#347;ciu do studia. Osoby bez ochraniaczy - nie b&#281;d&#261; obs&#322;ugiwane.</span>
                </li>
                <li className="c0 li-bullet-0"><span
                    className="c1">Zabrania si&#281; dotykania jakichkolwiek przedmiot&oacute;w s&#322;u&#380;&#261;cych do wykonywania tatua&#380;u lub piercingu, materia&#322;&oacute;w pomocniczych oraz rozk&#322;adania prywatnych rzeczy Klienta na stanowisku Tatuatora. &nbsp;</span>
                </li>
                <li className="c0 li-bullet-0"><span className="c1">&nbsp;Studio nie ponosi odpowiedzialno&#347;ci za rzeczy pozostawione w lokalu.</span>
                </li>
                <li className="c0 li-bullet-0"><span className="c1">&nbsp;Studio udziela wszystkich niezb&#281;dnych informacji na temat gojenia i piel&#281;gnacji wykonanego tatua&#380;u lub piercingu. Klient zobowi&#261;zany jest do stosowania si&#281; do zalece&#324; i wytycznych Tatuatora. Je&#380;eli Klient nie stosuje si&#281; do zalece&#324;, wszelkie poprawki tatua&#380;u dokonywane s&#261; za dodatkow&#261; op&#322;at&#261;, ustalon&#261; ka&#380;dorazowo indywidualnie z Klientem.</span>
                </li>
                <li className="c0 li-bullet-0"><span className="c1">&nbsp;Studio nie ponosi odpowiedzialno&#347;ci za nieprzestrzeganie przez Klienta zasad piel&#281;gnacji podczas procesu gojenia tatua&#380;u lub piercingu, a tak&#380;e nie ponosi odpowiedzialno&#347;ci za spos&oacute;b zachowania si&#281; barwnik&oacute;w na ciele, ze wzgl&#281;du na szeroko poj&#281;ty proces gojenia si&#281; cia&#322;a. </span>
                </li>
                <li className="c0 li-bullet-0"><span className="c1">W ramach ustalonego wynagrodzenia Studio nie zapewnia &#347;rodk&oacute;w do piel&#281;gnacji tatua&#380;u lub piercingu. Klient dokonuje zakupu &#347;rodk&oacute;w do piel&#281;gnacji tatua&#380;u lub piercingu na w&#322;asn&#261; r&#281;k&#281;. &nbsp;</span>
                </li>
                <li className="c0 li-bullet-0"><span className="c1">Tatuator mo&#380;e przerwa&#263; wykonanie zabiegu w przypadku stwierdzenia pogorszenia si&#281; samopoczucia Klienta lub wyst&#261;pienia zmian w strukturze sk&oacute;ry.</span>
                </li>
                <li className="c0 li-bullet-0"><span
                    className="c1">Tatuator, jak i Klient ze wzgl&#281;du na d&#322;ugo&#347;&#263; i z&#322;o&#380;ono&#347;&#263; wykonywania tatua&#380;u mog&#261; przerwa&#263; prace w celu regeneracji si&#322;, za&#322;atwienia potrzeb fizjologicznych lub spo&#380;ycia posi&#322;ku.</span>
                </li>
                <li className="c0 li-bullet-0"><span
                    className="c1">Tatuator nie ma obowi&#261;zku zapewnienia posi&#322;ku Klientowi, w przypadku wykonywania czasoch&#322;onnego tatua&#380;u.</span>
                </li>
                <li className="c0 li-bullet-0"><span
                    className="c1">Zabrania si&#281; spo&#380;ywania posi&#322;k&oacute;w w pomieszczeniu zabiegowym. Posi&#322;ki mo&#380;na spo&#380;ywa&#263; jedynie w pokoju do tego przeznaczonym.</span>
                </li>
                <li className="c0 li-bullet-0"><span className="c1">Tatuator mo&#380;e odm&oacute;wi&#263; wykonania tatua&#380;u w szczeg&oacute;lno&#347;ci, je&#380;eli: </span>
                </li>
            </ol>
            <ol className="c2 lst-kix_list_2-1 start" start={1}>
                <li className="c5 c6 li-bullet-0"><span className="c1">uzna, &#380;e wykonanie zabiegu zagra&#380;a zdrowiu Klienta lub Tatuatora;</span>
                </li>
                <li className="c5 c6 li-bullet-0"><span className="c1">Tatuator dostrze&#380;e u Klienta objawy choroby, kt&oacute;ra uniemo&#380;liwia wykonanie tatua&#380;u;</span>
                </li>
                <li className="c5 c6 li-bullet-0"><span className="c1">wyst&#281;puj&#261; inne przeciwwskazania do wykonania tatua&#380;u, jak pozostawanie w okresie ci&#261;&#380;y, karmienia piersi&#261; lub menstruacji;</span>
                </li>
                <li className="c5 c6 li-bullet-0"><span className="c1">Klient pozostaje pod wp&#322;ywem alkoholu lub innych substancji psychoaktywnych; </span>
                </li>
                <li className="c5 c6 li-bullet-0"><span className="c1">Klient jest agresywny, nie przestrzega zasad Regulaminu lub w inny spos&oacute;b uniemo&#380;liwia wykonanie tatua&#380;u.</span>
                </li>
            </ol>
            <ol className="c2 lst-kix_list_2-0" start={38}>
                <li className="c0 li-bullet-0"><span className="c1">Wykonanie tatua&#380;u lub piercingu wi&#261;&#380;e si&#281; z odczuwaniem przez Klienta b&oacute;lu o r&oacute;&#380;nym nasileniu.</span>
                </li>
                <li className="c0 li-bullet-0"><span
                    className="c1">Istnieje mo&#380;liwo&#347;&#263; podania &#347;rodk&oacute;w u&#347;mierzaj&#261;cych b&oacute;l. Koszt &#347;rodku znieczulaj&#261;cego dany obszar w kremie (nale&#380;y na&#322;o&#380;y&#263; przed wykonywan&#261; sesj&#261;) to 100z&#322;, koszt &#347;rodku znieczulaj&#261;cego w spray&rsquo;u (kt&oacute;rego mo&#380;na u&#380;ywa&#263; w trakcie sesji) 100z&#322;, pakiet dw&oacute;ch znieczule&#324; &ndash; 150z&#322;.</span>
                </li>
                <li className="c0 li-bullet-0"><span
                    className="c1">&nbsp;Wykonanie tatua&#380;u lub piercingu wi&#261;&#380;e si&#281; z przerwaniem ci&#261;g&#322;o&#347;ci nask&oacute;rka, co wi&#261;&#380;e z ryzykiem zaka&#380;enia m.in. wirusami WZW typu B i C niszcz&#261;cymi w&#261;trob&#281;</span>
                </li>
                <li className="c0 li-bullet-0"><span className="c1">W przypadku, gdy projekt dostarczany jest przez Klienta, Klient o&#347;wiadcza, &#380;e posiada maj&#261;tkowe prawa autorskie do jego wykorzystania i &#380;e projekt tatua&#380;u nie narusza praw autorskich osobistych lub maj&#261;tkowych os&oacute;b trzecich, w tym tak&#380;e prawa do wizerunku oraz d&oacute;br osobistych os&oacute;b trzecich i jest wolny od jakichkolwiek obci&#261;&#380;e&#324; lub ogranicze&#324; o charakterze rzeczowym lub obligacyjnym oraz wad prawnych i fizycznych.</span>
                </li>
                <li className="c0 li-bullet-0"><span className="c1">&nbsp;Klient wyra&#380;a zgod&#281; na wykorzystanie przez Przyjmuj&#261;cego zam&oacute;wienie materia&#322;&oacute;w uwieczniaj&#261;cych wykonany tatua&#380; lub piercing, w szczeg&oacute;lno&#347;ci zdj&#281;&#263; tatua&#380;u lub piercingu w mediach spo&#322;eczno&#347;ciowych. Studio zastrzega sobie prawa autorskie do wykonanych zdj&#281;&#263; i prac. Klient nie ma prawa przekazywania stworzonego dla niego projektu tatua&#380;u w celu jego ponownego u&#380;ycia w jakiejkolwiek formie czy zwielokrotniania bez zawarcia ze studiem odr&#281;bnej umowy licencyjnej.</span>
                </li>
                <li className="c0 li-bullet-0"><span className="c1">Tatua&#380; jest rzemios&#322;em artystycznym i jego efekt ko&#324;cowy mo&#380;e r&oacute;&#380;ni&#263; si&#281; od prezentowanego wzoru.</span>
                </li>
                <li className="c0 li-bullet-0"><span className="c1">Je&#347;li klient odwo&#322;a wizyt&#281;, na miejscu oka&#380;e si&#281;, &#380;e z jego winy nie mo&#380;na wykona&#263; us&#322;ugi, b&#261;d&#378; nie pojawi si&#281; w ustalonym terminie zadatek nie podlega zwrotowi.</span>
                </li>
                <li className="c0 li-bullet-0"><span
                    className="c1">Poprawki tatua&#380;y wykonanych w studiu s&#261; darmowe, je&#347;li klient bezpo&#347;rednio po wygojeniu tatua&#380;u (maksymalnie do p&oacute;&#322;tora miesi&#261;ca od wykonania us&#322;ugi) zg&#322;osi niedoci&#261;gni&#281;cia tatuatorowi, a nast&#281;pnie um&oacute;wi wizyt&#281; poprawkow&#261;. Wizyta na kt&oacute;rej naniesione maj&#261; zosta&#263; poprawki, musi zosta&#263; wykonana w maksymalnie miesi&#261;c od zg&#322;oszenia problemu tatuatorowi.</span>
                </li>
                <li className="c0 li-bullet-0"><span
                    className="c1">Poprawki tatua&#380;u s&#261; odp&#322;atne w nast&#281;puj&#261;cych przypadkach:</span>
                </li>
            </ol>
            <ol className="c2 lst-kix_list_2-1 start" start={1}>
                <li className="c5 c6 li-bullet-0"><span
                    className="c1">tatuator stwierdzi, i&#380; konieczno&#347;&#263; poprawek wynika z winy klienta (np. z&#322;a piel&#281;gnacja);</span>
                </li>
                <li className="c5 c6 li-bullet-0"><span className="c1">klient zg&#322;osi&#322; ch&#281;&#263; poprawki po up&#322;yni&#281;ciu p&oacute;&#322;tora miesi&#261;ca od wykonania us&#322;ugi;</span>
                </li>
                <li className="c10 c6 li-bullet-0"><span
                    className="c1">tatua&#380; wykonywany by&#322; na d&#322;oniach, stopach, palcach, &#322;okciu, wardze</span><span
                    className="c1">,</span></li>
                <li className="c6 c10 li-bullet-0"><span
                    className="c1">w przypadku konieczno&#347;ci dobicia koloru bia&#322;ego,</span>
                </li>
                <li className="c10 c6 li-bullet-0"><span
                    className="c1">je&#347;li Klient zaakceptowa&#322; dany wz&oacute;r, nast&#281;pnie po odbiciu kalki nie zg&#322;osi&#322; za&#380;ale&#324;, jednak po wykonaniu tatua&#380;u zauwa&#380;y on b&#322;&#281;dy, kt&oacute;re maj&#261; bezpo&#347;redni zwi&#261;zek z przypadkami takimi jak: z&#322;e t&#322;umaczenie tekstu, b&#322;&#281;dy gramatyczne, czy stylistyczne, ortograficzne.</span>
                </li>
            </ol>
            <ol className="c2 lst-kix_list_2-0" start={47}>
                <li className="c10 c8 c11 li-bullet-0"><span
                    className="c1">Je&#347;li Klient nie stawi si&#281; na um&oacute;wion&#261; poprawk&#281; tatua&#380;u/wymian&#281; kolczyka w uzgodnionym terminie, b&#261;d&#378; odwo&#322;a wizyt&#281; p&oacute;&#378;niej ni&#380; 2 tygodnie przed planowanym terminem - Klient b&#281;dzie musia&#322; ponie&#347;&#263; dodatkowe koszta. </span>
                </li>
            </ol>
            <p className="c10 c8"><span
                className="c1">W przypadku niestawienia si&#281; na darmow&#261; poprawk&#281; tatua&#380;u Klient b&#281;dzie musia&#322; zap&#322;aci&#263; w dniu jej wykonania 150z&#322;. </span>
            </p>
            <p className="c10 c8"><span
                className="c1">W przypadku niestawienia si&#281; na wymian&#281; kolczyka 50z&#322; + koszt wymienianego kolczyka/kolczyk&oacute;w.</span>
            </p>
        </div>
    </StyledRegulamin>
};
